jQuery(document).ready(function ($) {
    let $challengeList = $('.home-challenge__list');
    let $executeSlider = $('.home-execute__slider');
    let $answerSlider = $('.about-answer__slider');
    let $teamSlider = $('.about-team__slider');

    if ($(window).width() >= 1024) {
        $challengeList.on('init', function (event, slick) {
            var dots = $('.home-challenge__dots li');
            dots.each(function (k, v) {
                $(this).find('button').addClass('home-challenge__button-' + k);
            });
            var items = slick.$slides;
            items.each(function (k, v) {
                var text = $(this).find('.home-challenge__name').text();
                $('.home-challenge__button-' + k).text(text);
            });
        });
    }

    $challengeList.not('.slick-initialized').slick({
        arrows: false,
        dots: true,
        slidesToShow: 1,
        dotsClass: 'home-challenge__dots home-dots',
        appendDots: $('.dots-container'),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: true,
                    appendDots: $challengeList
                }
            }
        ]
    });

    $executeSlider.on('wheel', (function (e) {
        e.preventDefault();
        if (e.originalEvent.deltaY < 0) {
            $(this).slick('slickNext');
        } else {
            $(this).slick('slickPrev');
        }
    }));

    $answerSlider.on('wheel', (function (e) {
        e.preventDefault();
        if (e.originalEvent.deltaY < 0) {
            $(this).slick('slickNext');
        } else {
            $(this).slick('slickPrev');
        }
    }));

    $executeSlider.not('.slick-initialized').slick({
        arrows: true,
        slidesToShow: 1,
        vertical: true,
        infinite: true,
        autoplaySpeed: 3000,
        autoplay: true,
        centerMode: true,
        centerPadding: '5rem',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: false,
                    centerMode: false,
                    vertical: false,
                    dots: true,
                    autoplay: false,
                    slidesToShow: 1,
                    dotsClass: 'home-execute__dots home-dots',
                }
            },
        ]
    });

    $answerSlider.not('.slick-initialized').slick({
        arrows: true,
        slidesToShow: 1,
        vertical: true,
        infinite: true,
        autoplaySpeed: 3000,
        autoplay: true,
        centerMode: true,
        centerPadding: '5rem',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: false,
                    centerMode: false,
                    vertical: false,
                    dots: true,
                    autoplay: false,
                    slidesToShow: 1,
                    dotsClass: 'about-answer__dots home-dots',
                }
            },
        ]
    });


    if ($(window).width() < 768) {
        $teamSlider.not('.slick-initialized').slick({
            arrows: false,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dotsClass: 'about-team__dots home-dots',

        });
    }

});
